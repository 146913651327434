"use client";
import { useEffect, useRef, useState } from "react";

let isAdSenseScriptLoaded = false;

const AdSenseResponsive = ({
  adClient = "ca-pub-4631505357773597",
  adSlot,
  adFormat = "auto",
  fullWidthResponsive = true,
  style = {},
  className = "",
  fallback = null,
  timeout = 2000, // time to wait before marking unfilled (ms)
}) => {
  const wrapperRef = useRef(null);
  const adRef = useRef(null);
  const [isUnfilled, setIsUnfilled] = useState(false);

  useEffect(() => {
    if (!isAdSenseScriptLoaded) {
      const script = document.createElement("script");
      script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`;
      script.async = true;
      script.crossOrigin = "anonymous";
      document.head.appendChild(script);
      isAdSenseScriptLoaded = true;
    }

    const timeoutId = setTimeout(() => {
      const iframe = adRef.current?.querySelector("iframe");
      if (!iframe) {
        setIsUnfilled(true); // mark as unfilled
        if (wrapperRef.current) {
          wrapperRef.current.style.display = "none"; // auto-hide
        }
      }
    }, timeout);

    // Trigger ad rendering only if the ad hasn't been initialized yet
    setTimeout(() => {
      try {
        if (window.adsbygoogle && adRef.current) {
          // Check if the ad unit already has an iframe (meaning it's already initialized)
          const existingIframe = adRef.current.querySelector('iframe');
          if (!existingIframe) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        }
      } catch (e) {
        console.error("AdSense error:", e);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [adSlot, adClient]);

  return (
    <div
      ref={wrapperRef}
      className={className}
      style={{ ...style, position: "relative", width: "100%" }}
    >
      {!isUnfilled ? (
        <ins
          ref={adRef}
          className="adsbygoogle"
          style={{
            display: "block",
            width: "100%",
            textAlign: "center",
            minHeight: "250px",
            background: "#f9f9f9",
          }}
          data-ad-client={adClient}
          data-ad-slot={adSlot}
          data-ad-format={adFormat}
          data-full-width-responsive={fullWidthResponsive ? "true" : "false"}
        />
      ) : (
        fallback
      )}
    </div>
  );
};

export default AdSenseResponsive;
