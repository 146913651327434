import Image from 'next/image';
import Link from 'next/link';
import React from 'react'
import { Facebook, Linkedin, Twitter, Youtube } from "react-feather";

export function Footer() {
    const links = [
        { href: "/our-philosophy", label: "Our Philosophy" },
        { href: "/contact-us", label: "Contact us" },
        { href: "/join-us", label: "Join us" },
        { href: "/terms-and-conditions", label: "T&CS" },
        { href: "/privacy-policy", label: "Privacy policy" },
    ];

    const socialLinks = [
        { href: "https://www.facebook.com/TheIndependentSG/", icon: <Facebook size={18} stroke="#000" strokeWidth={1} /> },
        { href: "https://x.com/independentsg?lang=en", icon: <Twitter size={18} stroke="#000" strokeWidth={1} /> },
        { href: "https://www.linkedin.com/company/the-independent-singapore/?originalSubdomain=sg", icon: <Linkedin size={18} stroke="#000" strokeWidth={1} /> },
        { href: "https://www.youtube.com/c/TheIndependentSingapore", icon: <Youtube size={18} stroke="#000" strokeWidth={1} /> },
    ];

    return (
        <footer className='mt-auto'>
            <div className="container bg-slate-200 mx-auto  md:px-0 py-6 md:py-10 flex items-end gap-10 justify-between">
                <div className="w-full px-4 xl:px-16">
                    <div className="flex items-center border-b gap-6">
                        <div className="text-gray-700 font-bold">Follow Us</div>
                        <div className="flex space-x-4">
                            {socialLinks.map(link => (
                                <Link
                                    key={link.href}
                                    href={link.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {link.icon}
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-between items-center 1 border-black text-gray-600 text-sm mt-8 pt-8" style={{ borderTop: "1px solid #96a5ba" }}>
                        <div className="xl:flex items-center justify-between w-full flex-wrap ">
                            <div className='xl:flex items-center  space-y-6  xl:space-y-0'>
                                <Link href="/" className='flex items-center gap-4'>
                                    <span className="flex invert w-12 h-12 relative">
                                        <img
                                            src={"/img/logo.png"}
                                            alt="The Independent Logo"
                                        />
                                    </span>
                                    <span className="font-bold text-2xl text-gray-700 leading-tight">The Independent SG</span>
                                </Link>
                                <ul className='xl:flex xl:ml-8 gap-4 space-y-3 xl:space-y-0'>
                                    {links.map(link => (
                                        <li key={link.label}>
                                            <Link href={link.href} className="hover:underline font-slab">
                                                {link.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='flex mt-6 xl:mt-0'>
                                <span>© Copyright {new Date().getFullYear()} The Independent Singapore. All Rights Reserved.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
