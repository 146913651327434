"use client";

import React, { useState, useEffect } from "react";
import { ArrowUp, ArrowUpCircle } from "react-feather"; // Import the icon
import { Footer } from "./footer";

function Layout(props) {
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 100) { // Show button after 100px of scroll
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  // Scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <main className="container mx-auto flex-1 ">
        {props.children}
      </main>
      <Footer />
      
      {showScrollButton && (
        <div
          className="fixed bottom-5 bg-red-500 rounded-full p-2 right-5 cursor-pointer text-white h-12 w-12 flex items-center justify-center"
          onClick={scrollToTop}
        >
          <ArrowUp size={24} strokeWidth={1.5} />
        </div>
      )}
    </>
  );
}

export default Layout;
