import React, { useRef, useState, useEffect } from "react";
import { ChevronDown } from "react-feather";
import parse from "html-react-parser";

function CategoryDropdown({ categories, selectedCategory, onSelectCategory }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li className="relative" ref={dropdownRef}>
      <button
        className="text-gray-600 focus:outline-none font-slab text-sm text-center inline-flex items-center"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        More <ChevronDown size={18} />
      </button>
      <div
        style={{ right: 0, width: "240px" }}
        className={`z-10 ${isDropdownOpen ? "block" : "hidden"} absolute bg-white divide-y divide-gray-100 rounded-lg shadow-sm w-44 dark:bg-gray-700`}
      >
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200 border-t border-t-4 border-red-500 mt-4 cat-dropdown">
          {categories
            .filter(category => !["Asia", "Business"].includes(category))
            .map((category, index) => (
              <li key={index} className="font-slab">
                <button
                  onClick={() => {
                    onSelectCategory(category);
                    setIsDropdownOpen(false);
                  }}
                  className="block h-full w-full text-right px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {parse(category)}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </li>
  );
}

export default CategoryDropdown; 