// components/AdSense.js
'use client'; // This is needed since we're using client-side features

import { useEffect, useRef, useState } from 'react';

const AdSense = () => {
  const adRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Ensure this only runs on the client side
    if (typeof window === 'undefined') return;

    const initializeAd = () => {
      // Check if the ad unit already has an iframe or is in the process of being initialized
      const existingIframe = adRef.current?.querySelector('iframe');
      if (existingIframe || isInitialized) return;

      try {
        // Set initialized state before pushing to prevent duplicate initialization
        setIsInitialized(true);
        
        // Small delay to ensure DOM is ready
        setTimeout(() => {
          if (window.adsbygoogle) {
            window.adsbygoogle.push({});
          }
        }, 100);
      } catch (error) {
        console.error('AdSense initialization error:', error);
        setIsInitialized(false); // Reset on error
      }
    };

    // Initialize after a short delay to ensure DOM is ready
    const timer = setTimeout(initializeAd, 500);

    return () => clearTimeout(timer);
  }, [isInitialized]);

  return (
    <>
      {/* Load the AdSense script */}
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4631505357773597"
        crossOrigin="anonymous"
      />

      {/* Ad container */}
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-4631505357773597"
        data-ad-slot="5091052809"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </>
  );
};

export default AdSense;